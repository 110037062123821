import { Dashboards } from "@codman/shared/types";
import { CodmanStructure } from "./data-access-shared-api.types";

export const fakeCodmanStructure: CodmanStructure = [
    {
        name: "Dcra",
        tenant: "Dica",
        applications: [
            {
                name: Dashboards.Descriptives,
                referenceProfiles: [
                    {
                        id: "default-2021",
                        lid: "definitions2021",
                        subsets: [
                            {
                                id: 1,
                                label: "Klinisch",
                                years: [
                                    2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
                                    2019, 2020, 2021,
                                ],
                            },
                            {
                                id: 2,
                                label: "Colon",
                                years: [
                                    2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
                                    2019, 2020, 2021,
                                ],
                            },
                            {
                                id: 3,
                                label: "Rectum",
                                years: [
                                    2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
                                    2019, 2020, 2021,
                                ],
                            },
                            {
                                id: 4,
                                label: "Proms",
                                years: [2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                        ],
                    },
                    {
                        id: "default-2022",
                        lid: "definitions2022",
                        subsets: [
                            {
                                id: 1,
                                label: "Klinisch",
                                years: [
                                    2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
                                    2019, 2020, 2021,
                                ],
                            },
                            {
                                id: 2,
                                label: "Colon",
                                years: [
                                    2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
                                    2019, 2020, 2021,
                                ],
                            },
                            {
                                id: 3,
                                label: "Rectum",
                                years: [
                                    2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
                                    2020, 2021,
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                name: Dashboards.Indicators,
                pages: ["definitions2020", "definitions2021", "definitions2022"],
            },
            {
                name: Dashboards.Exploration,
                subsets: [
                    {
                        id: "primaryColonCancer",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "outcomes" }] },
                            { id: "promsOutcomes", sections: [{ id: "outcomes" }] },
                        ],
                    },
                    {
                        id: "primaryRectalCancer",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "outcomes" }] },
                            { id: "promsOutcomes", sections: [{ id: "outcomes" }] },
                        ],
                    },
                ],
            },
            {
                name: Dashboards.Patients,
                subsets: [
                    {
                        id: "rectal-cancer",
                        treatments: ["ostomy", "temporary-ostomy", "no-ostomy"],
                    },
                ],
            },
        ],
    },
    {
        name: "Duca",
        tenant: "Dica",
        applications: [
            {
                name: Dashboards.Descriptives,
                referenceProfiles: [
                    {
                        id: "default-2021",
                        lid: "definitions2021",
                        subsets: [
                            {
                                id: 1,
                                label: "Algemeen",
                                years: [
                                    2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
                                    2021,
                                ],
                            },
                            {
                                id: 2,
                                label: "Slokdarmcarcinoom (incl. slokdarm-maagovergang)",
                                years: [
                                    2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
                                    2021,
                                ],
                            },
                            {
                                id: 3,
                                label: "Maagcarcinoom",
                                years: [
                                    2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
                                    2021,
                                ],
                            },
                            {
                                id: 4,
                                label: "Proms",
                                years: [2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                        ],
                    },
                ],
            },
            {
                name: Dashboards.Indicators,
                pages: ["definitions2020", "definitions2021", "definitions2022"],
            },
            {
                name: Dashboards.Exploration,
                subsets: [
                    {
                        id: "oesophagealCarcinoma",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "outcomes" }] },
                            { id: "promsOutcomes", sections: [{ id: "outcomes" }] },
                        ],
                    },
                    {
                        id: "gastricCarcinoma",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "outcomes" }] },
                            { id: "promsOutcomes", sections: [{ id: "outcomes" }] },
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: "DlcaS",
        tenant: "Dica",
        applications: [
            {
                name: Dashboards.Descriptives,
                referenceProfiles: [
                    {
                        id: "default-2021",
                        lid: "definitions2021",
                        subsets: [
                            {
                                id: 1,
                                label: "Klinisch",
                                years: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                            {
                                id: 2,
                                label: "Verdenking longcarcinoom",
                                years: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                            {
                                id: 3,
                                label: "Mediastinale tumoren",
                                years: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                            {
                                id: 4,
                                label: "Metastasectomie",
                                years: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                            {
                                id: 5,
                                label: "Overige ingrepen (geen reïnterventies)",
                                years: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                        ],
                    },
                    {
                        id: "default-2022",
                        lid: "definitions2022",
                        subsets: [
                            {
                                id: 1,
                                label: "Klinisch",
                                years: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                            {
                                id: 2,
                                label: "Verdenking longcarcinoom",
                                years: [2012, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                            {
                                id: 3,
                                label: "Mediastinale tumoren",
                                years: [2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020],
                            },
                            {
                                id: 4,
                                label: "Metastasectomie",
                                years: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                            {
                                id: 5,
                                label: "Overige ingrepen (geen reïnterventies)",
                                years: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                        ],
                    },
                ],
            },
            {
                name: Dashboards.Indicators,
                pages: ["definitions2020", "definitions2021", "definitions2022"],
            },
            {
                name: Dashboards.Exploration,
                subsets: [
                    {
                        id: "resectionOfLungCarcinoma",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "outcomes" }] },
                            { id: "survivalOutcomes", sections: [{ id: "outcomes" }] },
                        ],
                    },
                    {
                        id: "mediastiumSurgery",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "outcomes" }] },
                            { id: "survivalOutcomes", sections: [{ id: "outcomes" }] },
                        ],
                    },
                    {
                        id: "metastasectomy",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "outcomes" }] },
                            { id: "survivalOutcomes", sections: [{ id: "outcomes" }] },
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: "Dato",
        tenant: "Dica",
        applications: [
            {
                name: Dashboards.Descriptives,
                referenceProfiles: [
                    {
                        id: "default-2021",
                        lid: "definitions2021",
                        subsets: [
                            {
                                id: 1,
                                label: "Primaire ingrepen",
                                years: [2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                            {
                                id: 2,
                                label: "Secundaire ingrepen en revisie",
                                years: [2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                            {
                                id: 3,
                                label: "Proms",
                                years: [2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                        ],
                    },
                    {
                        id: "default-2022",
                        lid: "definitions2022",
                        subsets: [
                            {
                                id: 1,
                                label: "Primaire ingrepen",
                                years: [2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                            {
                                id: 2,
                                label: "Secundaire ingrepen en revisie",
                                years: [2015, 2016, 2017, 2018, 2019, 2020, 2021],
                            },
                        ],
                    },
                ],
            },
            {
                name: Dashboards.Indicators,
                pages: ["definitions2020", "definitions2021", "definitions2022"],
            },
            {
                name: Dashboards.Exploration,
                subsets: [
                    {
                        id: "bariatricSurgery",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "outcomes" }] },
                            { id: "promsOutcomes", sections: [{ id: "outcomes" }] },
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: "DgeaDrce",
        tenant: "Dica",
        applications: [
            {
                name: Dashboards.Descriptives,
                referenceProfiles: [
                    {
                        id: "default-2021",
                        lid: "definitions-2021",
                        subsets: [
                            { id: 1, label: "DGEA", years: [2016, 2017, 2018, 2019, 2020, 2021] },
                            { id: 2, label: "DRCE", years: [2016, 2017, 2018, 2019, 2020, 2021] },
                        ],
                    },
                ],
            },
            { name: Dashboards.Indicators, pages: ["definitions2021", "definitions2022"] },
            {
                name: Dashboards.Exploration,
                subsets: [
                    {
                        id: "colonoscopy",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "ercp",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "gastroscopy",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "sigmoidoscopy",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "proctoscopy",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "eus",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "vce",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "balloonEnteroscopy",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "pegpejpec",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "liverBiopsy",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: "Pice",
        tenant: "Pice",
        applications: [
            {
                name: Dashboards.Descriptives,
                referenceProfiles: [
                    {
                        id: "default-2020",
                        lid: "definitions2020",
                        subsets: [
                            {
                                id: 1,
                                label: "PICE",
                                years: [
                                    2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017,
                                    2018, 2019, 2020, 2021,
                                ],
                            },
                        ],
                    },
                ],
            },
            { name: Dashboards.Indicators, pages: ["definitions2020"] },
        ],
    },
    {
        name: "AmsmReporting",
        tenant: "swab",
        applications: [
            { name: Dashboards.Indicators, pages: ["definitions2020"] },
            {
                name: Dashboards.Exploration,
                subsets: [
                    {
                        id: "quantitativeCharacteristics",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "antimicrobialIndications",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "antimicrobialProphylaxis",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "reservedAntibioticCourses",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "ivOralSwitchStreamlineAntibioticCourses",
                        pages: [
                            { id: "ivOralSwitch", sections: [{ id: "medicalParameters" }] },
                            { id: "streamline", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                    {
                        id: "therapeuticDrugMonitoring",
                        pages: [
                            { id: "clinicalOutcomes", sections: [{ id: "medicalParameters" }] },
                        ],
                    },
                ],
            },
        ],
    },
];
