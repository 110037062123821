export interface IRegistryConfig {
    showDataDescription: boolean;
}

export interface IRegistryConfigMap {
    [registry: string]: IRegistryConfig;
}

export const registryConfiguration: IRegistryConfigMap = {
    // IDR registries
    Icra: {
        showDataDescription: true,
    },

    // DICA registries
    Daci: {
        showDataDescription: true,
    },
    Dasa: {
        showDataDescription: true,
    },
    Dato: {
        showDataDescription: true,
    },
    Dbir: {
        showDataDescription: true,
    },
    Dcra: {
        showDataDescription: true,
    },
    DcraR: {
        showDataDescription: true,
    },
    Dgda: {
        showDataDescription: false,
    },
    Dgea: {
        showDataDescription: false,
    },
    DgeaDrce: {
        showDataDescription: true,
    },
    Dgoa: {
        showDataDescription: true,
    },
    Dhba: {
        showDataDescription: true,
    },
    Dhfa: {
        showDataDescription: true,
    },
    Dhna: {
        showDataDescription: true,
    },
    Diha: {
        showDataDescription: true,
    },
    DlcaL: {
        showDataDescription: true,
    },
    DlcaR: {
        showDataDescription: true,
    },
    DlcaS: {
        showDataDescription: true,
    },
    DlcaSErats: {
        showDataDescription: true,
    },
    Dmtr: {
        showDataDescription: true,
    },
    Dnma: {
        showDataDescription: true,
    },
    Dpa: {
        showDataDescription: true,
    },
    Dpaa: {
        showDataDescription: true,
    },
    Dpard: {
        showDataDescription: true,
    },
    Dpca: {
        showDataDescription: true,
    },
    Dppa: {
        showDataDescription: true,
    },
    Drce: {
        showDataDescription: false,
    },
    Dsaa: {
        showDataDescription: true,
    },
    Duca: {
        showDataDescription: true,
    },
    Epsa: {
        showDataDescription: true,
    },
    Nbca: {
        showDataDescription: true,
    },
    NbcaR: {
        showDataDescription: true,
    },

    // NIV registries
    Draim: {
        showDataDescription: false,
    },
    Demo: {
        showDataDescription: false,
    },

    // SWAB registries
    AmsmReporting: {
        showDataDescription: false,
    },

    // PICE registries
    Pice: {
        showDataDescription: false,
    },
    PiceW: {
        showDataDescription: false,
    },

    // RIVM registries
    Gli: {
        showDataDescription: false,
    },

    // DICA WGZ registries
    DcraWgz: {
        showDataDescription: false,
    },
    DhfaWgz: {
        showDataDescription: false,
    },
};
