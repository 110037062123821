import { Observable, combineLatest, of } from "rxjs";
import { debounceTime, map } from "rxjs/operators";

import { BenchmarkType } from "@codman/exploration/util-interfaces";

import {
    IOutcome,
    IRegistryStructure,
    ISubsetConfiguration,
    MedicalItem,
    OutcomeConfiguration,
    OutcomeWithConfiguration,
    Regions,
} from "./data-access-api.types";
import {
    FullNavigationState,
    IRegistryStructureLookup,
    NavigationState,
} from "./data-access-state.service";
import {
    fakeBenchmarkConfiguration,
    fakeFilters,
    fakeMedicalParameters,
    fakeOutcomesConfiguration,
    fakeRegistryConfiguration,
    fakeRegistryStructure,
    fakeSharedProviders,
    fakeSubsetConfiguration,
    fakeYearsFilterCount,
} from "./fake-api";

export class DataAccessStateMockService {
    readonly currentRegistry$ = of("Dato");
    readonly currentSubset$ = of("bariatricSurgery");
    readonly currentPage$ = of("clinicalOutcomes");
    readonly connectProviders$ = of(undefined);
    readonly regions$ = of(undefined);
    readonly selectedBenchmark$ = of("national");
    readonly benchmarkProviderType$ = of(undefined);
    readonly benchmarkApiParams$ = of(undefined);

    readonly navigationState$: Observable<NavigationState> = combineLatest([
        this.currentRegistry$,
        this.currentSubset$,
    ]).pipe(
        debounceTime(10),
        map(([registryId, subsetId]) => ({
            registryId,
            subsetId,
        })),
    );

    readonly fullNavigationState$: Observable<FullNavigationState> = combineLatest([
        this.currentRegistry$,
        this.currentSubset$,
        this.currentPage$,
    ]).pipe(
        debounceTime(10),
        map(([registryId, subsetId, pageId]) => ({
            registryId,
            subsetId,
            pageId,
        })),
    );

    readonly registryStructure$ = of(fakeRegistryStructure);

    readonly registryConfiguration$ = of(fakeRegistryConfiguration);

    readonly registryStructureLookup$ = this.registryStructure$.pipe(
        map(structure => this._getRegistryStructureLookup(structure)),
    );

    readonly medicalParameters$ = of(fakeMedicalParameters);

    readonly filters$ = of(fakeFilters);

    readonly subsetConfiguration$ = of(fakeSubsetConfiguration);

    readonly yearFilter$ = of(fakeYearsFilterCount);

    readonly outcomesConfiguration$ = of(fakeOutcomesConfiguration);

    readonly sharedProviders$ = of(fakeSharedProviders);

    readonly registryPermissions$ = of(["read", "read_patients"]);

    readonly benchmarkConfiguration$ = of(fakeBenchmarkConfiguration);

    setCurrentRegistry(id: string): void {
        //
    }

    setCurrentSubset(subsetId: string): void {
        //
    }

    setCurrentPage(pageId: string): void {
        //
    }

    updateConnectProviders(providers?: number[]): void {
        //
    }

    updateSelectedBenchmark(selectedBenchmark: BenchmarkType): void {
        //
    }

    updateBenchmarkProviderType(benchmarkProviderType?: string): void {
        //
    }

    updateRegions(regions: Regions): void {
        //
    }

    mergeConfigurationInOutcomes(
        outcome: IOutcome,
        subsetConfiguration: ISubsetConfiguration,
        medicalParameter?: MedicalItem,
        outcomeConfiguration?: OutcomeConfiguration,
    ): OutcomeWithConfiguration {
        return {
            ...outcome,
            calculationType: "Percentage",
            formatter: "percent",
            unit: "",
            ...outcomeConfiguration,
        };
    }

    private _getRegistryStructureLookup(structure: IRegistryStructure): IRegistryStructureLookup {
        const lookup = structure.subsets.reduce((lookup, subset) => {
            lookup[subset.id] = subset.pages.reduce((pagesLookup, page) => {
                pagesLookup[page.id] = page.sections.map(section => section.id);
                return pagesLookup;
            }, <{ [pageId: string]: string[] }>{});
            return lookup;
        }, <IRegistryStructureLookup>{});
        return lookup;
    }
}
