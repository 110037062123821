export interface IBenchmarkConfiguration {
    regions: string[];
    defaultRegion?: string;
    providerTypes: string[];
    defaultProviderType?: string;
    connect: IConnectProvider[];
}

export interface IConnectProvider {
    id: number;
    name: string;
}

export type Benchmark =
    | INationalBenchmark
    | IRegionalBenchmark
    | IInternationalBenchmark
    | IConnectBenchmark
    | IProviderTypeBenchmark;

export interface IPatientsCount {
    numerator: number;
    denominator: number;
    connectBenchmarkNumerator: number;
    connectBenchmarkDenominator: number;
    nationalBenchmarkNumerator: number;
    nationalBenchmarkDenominator: number;
    providerTypeBenchmarkNumerator: number;
    providerTypeBenchmarkDenominator: number;
}

/**
 * @deprecated
 * Should be replaced with regional
 */
export interface INationalBenchmark {
    /**
     * @deprecated
     * Should be replaced with regional
     */
    type: "national";
}

export interface IRegionalBenchmark {
    type: "regional";
    regions: Regions;
}

export interface IInternationalBenchmark {
    type: "international";
}

export interface IConnectBenchmark {
    type: "connect";
    connectProviders: number[];
    regions: Regions;
}

export interface IProviderTypeBenchmark {
    type: "providerType";
    providerType: string;
    regions: Regions;
}

export type Regions = [string, ...string[]];
export type BenchmarkType = Benchmark["type"];
export type Provider =
    | "ownProvider"
    | "internationalBenchmark"
    | "nationalBenchmark"
    | "regionalBenchmark"
    | "providerTypeBenchmark"
    | "connectBenchmark";

export const INITIAL_BENCHMARK: Benchmark = {
    type: "regional",
    regions: ["nl"],
};

export const INITIAL_CA_BENCHMARK: Benchmark = {
    type: "regional",
    regions: ["ca"],
};
