import { Observable, of } from "rxjs";

import {
    FilterCounts,
    MedicalItems,
    IOutcome,
    IPatientCount,
    IRegistryStructure,
    ITrendMoment,
    Filters,
    PatientList,
    TimeRangeFilterRange,
    FilterParams,
    OutcomesConfiguration,
    IOutcomeTrend,
    IRegistryConfiguration,
    OutcomeConfiguration,
    ISubsetConfiguration,
    ISection,
    IProvider,
    ReferenceOutcome,
    FilterComparison,
    IBenchmarkConfiguration,
    IEventAnalysis,
    BenchmarkParams,
} from "./data-access-api.types";
import { delay } from "rxjs/operators";
import {
    fakeBenchmarkConfiguration,
    fakeDistributionPercentageOutcome,
    fakeEventAnalysis,
    fakeFilterComparisons,
    fakeFilterCounts,
    fakeFilters,
    fakeMedicalParameters,
    fakeOtherOutcomeProviders,
    fakeOutcomesConfiguration,
    fakeOutcomeTrend,
    fakePageOutcomes,
    fakePatientCount,
    fakePatientList,
    fakePatientTrend,
    fakeRegistryConfiguration,
    fakeRegistryStructure,
    fakeSectionOutcomes,
    fakeSharedProviders,
    fakeSingleOutcome,
    fakeStatus,
    fakeSubsetConfiguration,
    fakeYearsFilterCount,
} from "./fake-api";
import { Range } from "@codman/shared/util-filters";
import { IStatus } from "@codman/shared/types";

export class DataAccessApiMockService {
    /**
     * Configuration
     */
    getRegistryStructure(
        registryId = "Dato",
        organizationId: number,
    ): Observable<IRegistryStructure> {
        return of(fakeRegistryStructure).pipe(delay(50));
    }

    getRegistryConfiguration(
        registryId = "Dato",
        organizationId: number,
    ): Observable<IRegistryConfiguration> {
        return of(fakeRegistryConfiguration).pipe(delay(50));
    }

    getBenchmarkConfiguration(
        registryId: string,
        organizationId: number,
    ): Observable<IBenchmarkConfiguration> {
        return of(fakeBenchmarkConfiguration);
    }

    getMedicalParameters(
        registryId = "Dato",
        subsetId = "bariatricSurgery",
    ): Observable<MedicalItems> {
        return of(fakeMedicalParameters).pipe(delay(50));
    }

    getFilters(registryId = "Dato", subsetId = "bariatricSurgery"): Observable<Filters> {
        return of(fakeFilters).pipe(delay(50));
    }

    getSubsetConfiguration(
        registryId: string,
        subsetId: string,
        organizationId: number,
    ): Observable<ISubsetConfiguration> {
        return of(fakeSubsetConfiguration);
    }

    getOutcomeConfiguration(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
    ): Observable<OutcomeConfiguration> {
        return of({ maxValue: 5, outcomeType: "SingleMeasurement" });
    }

    getPageOutcomes(
        registryId: string,
        subsetId: string,
        pageId: string,
        organizationId: number,
    ): Observable<ISection[]> {
        return of(fakePageOutcomes);
    }

    getOutcomesConfiguration(
        registryId = "Dato",
        subsetId = "bariatricSurgery",
        organizationId: number,
    ): Observable<OutcomesConfiguration> {
        return of(fakeOutcomesConfiguration).pipe(delay(50));
    }

    /**
     * Connect
     */
    getSharedProviders(registryId: string, providerId: number): Observable<IProvider[]> {
        return of(fakeSharedProviders);
    }

    /**
     * Filters
     */
    getFilterCounts(
        registryId = "Dato",
        subsetId = "bariatricSurgery",
        outcomeId = "age",
        organizationId = 10003,
        years?: Range,
        filters?: FilterParams,
    ): Observable<FilterCounts> {
        return of(fakeFilterCounts);
    }

    getYearsFilterCount(
        registryId = "Dato",
        subsetId = "bariatricSurgery",
        organizationId = 10003,
    ): Observable<TimeRangeFilterRange> {
        return of(fakeYearsFilterCount).pipe(delay(50));
    }

    /**
     * Outcomes
     */
    getSingleOutcome(
        registryId = "Dato",
        subsetId = "bariatricSurgery",
        outcomeId = "preoperativeAndPostoperativeComplications",
        organizationId = 10003,
        benchmarkApiParams: BenchmarkParams = {},
        years?: Range,
        filters?: FilterParams,
    ): Observable<IOutcome> {
        if (outcomeId === "diabetesImprovementWithinOneYear") {
            return of(fakeDistributionPercentageOutcome).pipe(delay(50));
        } else {
            return of(fakeSingleOutcome).pipe(delay(50));
        }
    }

    getSectionOutcomes(
        registryId = "Dato",
        subsetId = "bariatricSurgery",
        pageId = "clinicalOutcomes",
        sectionId = "outcomes",
        organizationId = 10003,
        benchmarkApiParams: BenchmarkParams = {},
        years?: Range,
        filters?: FilterParams,
    ): Observable<IOutcome[]> {
        return of(fakeSectionOutcomes).pipe(delay(50));
    }

    getOtherOutcomeProviders(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams = {},
        years?: Range,
        filters?: FilterParams,
    ): Observable<ReferenceOutcome[]> {
        return of(fakeOtherOutcomeProviders).pipe(delay(50));
    }

    getOutcomeTrend(
        registryId = "Dato",
        subsetId = "bariatricSurgery",
        outcomeId = "preoperativeAndPostoperativeComplications",
        interval = "year",
        organizationId = 10003,
        benchmarkApiParams: BenchmarkParams = {},
        years?: Range,
        filters?: FilterParams,
    ): Observable<IOutcomeTrend[]> {
        return of(fakeOutcomeTrend).pipe(delay(50));
    }

    getFilterComparisons(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        filterGroupId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams = {},
        years?: Range,
        measurementMoment?: string,
        filters?: FilterParams,
    ): Observable<FilterComparison[]> {
        return of(fakeFilterComparisons).pipe(delay(50));
    }

    getEventAnalysis(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams = {},
        years?: Range,
        filters?: FilterParams,
    ): Observable<IEventAnalysis> {
        return of(fakeEventAnalysis);
    }

    /**
     * Patient Counts
     */
    getPatientsCount(
        registryId = "Dato",
        subsetId = "bariatricSurgery",
        organizationId = 10003,
        years?: Range,
        filters?: FilterParams,
    ): Observable<IPatientCount> {
        return of(fakePatientCount).pipe(delay(50));
    }

    getPatientTrend(
        registryId = "Dato",
        subsetId = "bariatricSurgery",
        interval = "year",
        organizationId = 10003,
        years?: Range,
        filters?: FilterParams,
    ): Observable<ITrendMoment[]> {
        return of(fakePatientTrend).pipe(delay(50));
    }

    /**
     * Patient List
     */
    getPatientsList(
        registryId = "Dato",
        subsetId = "bariatricSurgery",
        organizationId = 10003,
        years?: Range,
        filters?: FilterParams,
    ): Observable<PatientList> {
        return of(fakePatientList).pipe(delay(50));
    }

    getStatus(): Observable<IStatus> {
        return of(fakeStatus);
    }
}
