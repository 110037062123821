import { TimeRange } from "@codman/shared/util-filters";
import { BenchmarkType } from "@codman/exploration/util-interfaces";
import { LgConvertedBoxplotItem } from "@logex/framework/lg-charts";

/**
 * Parameters
 */
export interface NonFilterParams {
    timeRange?: TimeRange;
    connectBenchmarkProviders?: number[];
    regions?: string;
    measurementMoment?: string;
    offset?: number;
    count?: number;
    sortBy?: string;
    sortType?: ISortDirection;
    providerType?: string;
}

export type FilterParams = FilterParam[];
export type FilterParam =
    | CategoryQueryParams
    | RangeQueryParams
    | StringQueryParams
    | NumberQueryParams
    | TimeRangeQueryParams;

export interface CategoryQueryParams extends QueryParamsBase {
    type: "category";
    values: Array<number | string>;
}

export interface RangeQueryParams extends QueryParamsBase {
    type: "range";
    min: number;
    max: number;
}

export interface TimeRangeQueryParams extends QueryParamsBase {
    type: "timeRange";
    minYear: number;
    maxYear: number;
    minMonth: number;
    maxMonth: number;
}

export interface StringQueryParams extends QueryParamsBase {
    type: "string";
    value: string;
}

export interface NumberQueryParams extends QueryParamsBase {
    type: "number";
    value: number;
}

export interface QueryParamsBase {
    id: string;
}

export const SEPARATORS = {
    filters: "&",
    nameValue: "=",
    values: ",",
    range: "~",
    items: "-",
};

export const ORG_ID_HEADER = "x-organization-id";

/**
 * Configuration
 */
export interface IRegistryStructure {
    id: string;
    subsets: ISubsetStructure[];
}

export interface IRegistryConfiguration {
    moments: { [key: string]: string };
    benchmarkTypes: BenchmarkType[];
    providersCountThreshold?: number;
}

/**
 * Benchmark Configuration
 */
export interface IBenchmarkConfiguration {
    regions: string[];
    defaultRegion?: string;
    providerTypes: string[];
    defaultProviderType?: string;
    connect: IProvider[];
}

export interface ISubsetStructure {
    id: string;
    pages: IPageStructure[];
}

export interface IPageStructure {
    id: string;
    sections: ISectionStructure[];
}

export interface ISectionStructure {
    id: string;
}

export type MedicalItems = {
    [outcomeCode: string]: MedicalItem;
};

export type MedicalItem =
    | ICategoricalMedicalItem
    | INumericalMedicalItem
    | IStringMedicalItem
    | ITimeToEventMedicalItem;

export interface IMedicalItemConfigBase {}

export interface ICategoricalMedicalItem extends IMedicalItemConfigBase {
    medicalParameterType: "Categorical";
    options: {
        [key: string]: string;
    };
}

export type Unit =
    | "days"
    | "euros"
    | "grams"
    | "hours"
    | "kgPerM2"
    | "lymphNodes"
    | "minutes"
    | "mlPerMinute"
    | "mmHg"
    | "mgPerLiter"
    | "mgPerMmole"
    | "mmolPerMole"
    | "months"
    | "mumolPerLiter"
    | "patients"
    | "percentage"
    | "prescriptions"
    | "score"
    | "years";

export interface INumericalMedicalItem extends IMedicalItemConfigBase {
    medicalParameterType: "Numerical";
    unit?: Unit;
}

export interface IStringMedicalItem extends IMedicalItemConfigBase {
    medicalParameterType: "String";
}

export interface ITimeToEventMedicalItem extends IMedicalItemConfigBase {
    medicalParameterType: "TimeToEvent";
    unit?: Unit;
}

export type Filters = IFilterGroup[];

export interface IFilterGroup {
    groupId: string;
    filters: Filter[];
}

export type Filter =
    | ICategoryFilter
    | IHistogramRangeFilter
    | IRangeFilter
    | IStringFilter
    | IYearFilter
    | ITimeRangeFilter;

export interface IFilterBase {
    outcomeCode: string;
}

export interface IYearFilter extends IFilterBase {
    filterType: "Year";
}
export interface ITimeRangeFilter extends IFilterBase {
    filterType: "TimeRange";
}
export interface ICategoryFilter extends IFilterBase {
    filterType: "Category";
}
export interface IRangeFilter<T = "Range"> extends IFilterBase {
    filterType: T;
    minValue: number;
    maxValue: number;
}

export interface IHistogramRangeFilter extends IRangeFilter<"HistogramRange"> {}

export interface IStringFilter extends IFilterBase {
    filterType: "String";
}

export interface ISubsetConfiguration {
    analysisUnit: AnalysisUnit;
    patientListItems: string[];
    filterGroups: IFilterGroup[];
    medicalParameters: MedicalItems;
    defaultNumberOfYearsSelected?: number;
}

export type CalculationType = ReferenceOutcome["type"];

export type AnalysisUnit =
    | "Admissions"
    | "Episodes"
    | "Explants"
    | "Implants"
    | "Patients"
    | "Prescriptions"
    | "Procedures"
    | "Treatments"
    | "Visits"
    | "Courses";

export type OutcomesConfiguration = {
    [outcomeCode: string]: OutcomeConfiguration;
};

export type VisualType =
    | "TableSimple"
    | "BarchartHorizontal"
    | "BarchartVertical"
    | "Linechart"
    | "FunnelPlot"
    | "Piechart"
    | "Histogram"
    | "StackedBarAndLine"
    | "Boxplot"
    | "KaplanMeier"
    | "LineChart";

export interface OutcomeConfiguration {
    maxValue?: number;
    visualType?: VisualType;
    decimalPoints?: number;
    outcomeType: "SingleMeasurement" | "MultiMeasurement" | "KaplanMeier";
    funnelPlotConfidenceInterval?: ConfidenceInterval;
}

export type ConfidenceInterval = "95" | "99.5";

export interface ISection {
    sectionId: string;
    outcomes: string[];
}

export interface IProvider {
    id: number;
    name: string;
}

export interface IProviderType {
    id: number;
    name: string;
}

export type Regions = [string, ...string[]];

/**
 * Filters
 */
export type FilterCounts = Record<string | number, number>;
export interface YearsFilterRange {
    min: number | null;
    max: number | null;
}
export interface TimeRangeFilterRange {
    minDate?: string;
    maxDate?: string;
    minYear: number | null;
    maxYear: number | null;
    minMonth: number | null;
    maxMonth: number | null;
}

/**
 * Outcomes
 */
export interface IOutcome {
    outcomeId: string;
    ownProvider: ReferenceOutcome;
    nationalBenchmark?: ReferenceOutcome;
    regionalBenchmark?: ReferenceOutcome;
    providerTypeBenchmark?: ReferenceOutcome;
    connectBenchmark?: ReferenceOutcome;
    internationalBenchmark?: ReferenceOutcome;
    sharedProviders?: ISharedProviderOutcome[];
    medicalParameterId: string;
}

export type ReferenceOutcome = (
    | IPercentageOutcome
    | IMedianOutcome
    | IAverageOutcome
    | ICountOutcome
    | IDistributionPercentageOutcome
    | IEventAnalysisOutcome
) &
    (AnonymizedOutcome | NotAnonymizedOutcome);

interface IReferenceOutcomeBase {
    numberOfPatients?: number;
    numberOfProviders?: number;
    anonymized: boolean;
    moments?: ReferenceMoment[];
}

export interface IPercentageOutcome extends IReferenceOutcomeBase {
    type: "Percentage";
    percentage?: number;
    numerator?: number;
    denominator?: number;
    confidenceIntervalLower?: number;
    confidenceIntervalUpper?: number;
}

export interface IMedianOutcome extends IReferenceOutcomeBase {
    type: "Median";
    median?: number;
    confidenceIntervalLower?: number;
    confidenceIntervalUpper?: number;
    lowerBound?: number;
    upperBound?: number;
    isConnect?: boolean;
}

export interface IAverageOutcome extends IReferenceOutcomeBase {
    type: "Average";
    average?: number;
    confidenceIntervalLower?: number;
    confidenceIntervalUpper?: number;
}

export interface ICountOutcome extends IReferenceOutcomeBase {
    type: "Count";
    count?: number;
}

export interface IEventAnalysisOutcome extends IReferenceOutcomeBase {
    type: "EventAnalysis";
}

export interface IDistributionPercentageOutcome extends IReferenceOutcomeBase {
    type: "DistributionPercentage";
    distribution?: { [optionId: string]: IDistributionPercentage };
}

export interface IDistributionPercentage {
    percentage: number;
    numerator: number;
    denominator: number;
    confidenceIntervalLower?: number;
    confidenceIntervalUpper?: number;
    // TODO: confirm if this can ever be anonymized
    anonymized?: boolean;
}

export interface ISharedProviderOutcome {
    providerId: number;
    score: ReferenceOutcome;
}

export interface IOutcomeTrend {
    year: number;
    quarter?: number;
    month?: number;
    anonymized?: boolean;
    ownProvider: ReferenceOutcome | null;
    nationalBenchmark?: ReferenceOutcome;
    regionalBenchmark?: ReferenceOutcome;
    providerTypeBenchmark?: ReferenceOutcome;
    connectBenchmark?: ReferenceOutcome;
    internationalBenchmark?: ReferenceOutcome;
}

export interface IEventAnalysis {
    eventName: string;
    intervals: IEventAnalysisInterval[];
}

export interface IEventAnalysisInterval {
    interval: number;
    ownProvider?: IEventAnalysisIntervalReference | AnonymizedOutcome;
    nationalBenchmark?: IEventAnalysisIntervalReference | AnonymizedOutcome;
    connectBenchmark?: IEventAnalysisIntervalReference | AnonymizedOutcome;
    providerTypeBenchmark?: IEventAnalysisIntervalReference | AnonymizedOutcome;
    // sharedProviders?: IEventAnalysisSharedProviderReference[];
}

export interface IEventAnalysisSharedProviderReference {
    providerId: number;
    score: IEventAnalysisIntervalReference | AnonymizedOutcome;
}

export interface IEventAnalysisIntervalReference {
    type: "EventAnalysis";
    remainingPercentage: number;
    confidenceIntervalLower: number;
    confidenceIntervalUpper: number;
    numerator: number;
    /**
     * numberOfPatients - also the denominator
     */
    numberOfPatients?: number;
    anonymized: false;
    eventCount: number;
    censoredCount: number;
}

export type FilterComparison =
    | IRangeFilterComparison
    | ICategoryFilterComparison
    | IStringFilterComparison;

export interface IRangeFilterComparison extends IFilterComparisonBase {
    filterType: "Range" | "HistogramRange";
    comparisonData: IRangeFilterOptionOutcome[];
}

export interface ICategoryFilterComparison extends IFilterComparisonBase {
    filterType: "Category";
    comparisonData: ICategoryFilterOptionOutcome[];
}

export interface IStringFilterComparison extends IFilterComparisonBase {
    filterType: "String";
    comparisonData: IStringFilterOptionOutcome[];
}

export interface IFilterComparisonBase {
    filterName: string;
}

export interface IRangeFilterOptionOutcome {
    filterValue: string;
    outcome: {
        ownProvider: RangeFilterOutcome;
        nationalBenchmark?: RangeFilterOutcome;
        connectBenchmark?: RangeFilterOutcome;
        internationalBenchmark?: RangeFilterOutcome;
        providerTypeBenchmark?: RangeFilterOutcome;
    };
}

export type RangeFilterOutcome = (
    | IRangeFilterPercentageOutcome
    | IRangeFilterMedianOutcome
    | IRangeFilterAverageOutcome
    | IRangeFilterCountOutcome
    | IRangeFilterDistributionPercentageOutcome
) &
    (AnonymizedOutcome | NotAnonymizedOutcome);

export interface IRangeFilterOutcomeBase {
    moments?: ReferenceMoment[];
}
export interface IRangeFilterPercentageOutcome extends IRangeFilterOutcomeBase {
    type: "Percentage";
    percentage?: number;
}

export interface IRangeFilterMedianOutcome extends IRangeFilterOutcomeBase {
    type: "Median";
    median?: number;
}

export interface IRangeFilterAverageOutcome extends IRangeFilterOutcomeBase {
    type: "Average";
    average?: number;
}

export interface IRangeFilterCountOutcome extends IRangeFilterOutcomeBase {
    type: "Count";
    count?: number;
}

export interface IRangeFilterDistributionPercentageOutcome extends IRangeFilterOutcomeBase {
    type: "DistributionPercentage";
    distribution: { [optionId: string]: IDistributionPercentage };
}

export interface ICategoryFilterOptionOutcome {
    filterValue: string;
    outcome: {
        ownProvider: ReferenceOutcome;
        nationalBenchmark?: ReferenceOutcome;
        regionalBenchmark?: ReferenceOutcome;
        providerTypeBenchmark?: ReferenceOutcome;
        connectBenchmark?: ReferenceOutcome;
        internationalBenchmark?: ReferenceOutcome;
    };
}

export interface IStringFilterOptionOutcome {
    filterValue: string;
    outcome: {
        ownProvider: ReferenceOutcome;
        nationalBenchmark?: ReferenceOutcome;
        connectBenchmark?: ReferenceOutcome;
        internationalBenchmark?: ReferenceOutcome;
        providerTypeBenchmark?: ReferenceOutcome;
    };
}

export interface AnonymizedOutcome {
    anonymizationType: AnonymizationType[];
    anonymized: true;
}

export interface NotAnonymizedOutcome {
    anonymized: false;
}

export interface IBoxpoltFilterOptionDatum {
    key: string;
    value: IBoxplotComparingItem;
}

export interface IBoxplotComparingItem {
    main: IBoxplotConvertedItem;
    comparing?: IBoxplotConvertedItem;
    index?: number;
}

export interface IBoxplotConvertedItem extends LgConvertedBoxplotItem {
    anonymized: boolean;
    isConnect?: boolean;
}

export type AnonymizationType = "NumberOfPatients" | "NumberOfProviders" | "Partial";

/**
 * Patient Counts
 */
export interface IPatientCount {
    numerator: number;
    denominator: number;
    connectBenchmarkNumerator: number;
    connectBenchmarkDenominator: number;
    nationalBenchmarkNumerator: number;
    nationalBenchmarkDenominator: number;
    providerTypeBenchmarkNumerator: number;
    providerTypeBenchmarkDenominator: number;
}

export interface ITrendMoment {
    year: number;
    quarter?: number;
    month?: number;
    count: number | null;
}

export type PatientList = IPatientListItem[];

export interface IPatientListItem {
    upn: string;
    medicalParameters: {
        [outcomeId: string]: string | null;
    };
}

export type ISortDirection = "Ascending" | "Descending";

export type ReferenceMoment = (IPercentageMoment | IMedianMoment | IAverageMoment | ICountMoment) &
    (AnonymizedOutcome | NotAnonymizedOutcome);
export interface IReferenceMoments {
    numberOfPatients?: number;
    numberOfProviders?: number;
    confidenceIntervalLower?: number;
    confidenceIntervalUpper?: number;
    momentId: string;
    momentName?: string;
    anonymized: boolean;
}

export interface IPercentageMoment extends IReferenceMoments {
    type: "Percentage";
    percentage?: number;
    numerator?: number;
    denominator?: number;
}

export interface IMedianMoment extends IReferenceMoments {
    type: "Median";
    median?: number;
}

export interface IAverageMoment extends IReferenceMoments {
    type: "Average";
    average?: number;
}

export interface ICountMoment extends IReferenceMoments {
    type: "Count";
    count?: number;
}

/**
 * Utility
 */

export interface OutcomeWithConfiguration extends IOutcome, Partial<OutcomeConfiguration> {
    unit: string;
    calculationType: CalculationType;
    formatter: string;
    option?: { name: string; id: string };
}

export interface IDataExportOutcome {
    id: string;
    outcome: OutcomeWithConfiguration;
    trend: IOutcomeTrend[];
    confidenceType?: ConfidenceInterval | null;
    otherProviders: ReferenceOutcome[];
}

export interface IDataExportSection {
    name: string;
    outcomes: IDataExportOutcome[];
}
export interface IDataExportPage {
    name: string;
    sections: IDataExportSection[];
}

export interface BenchmarkParams {
    regions?: string;
    connectBenchmarkProviders?: number[];
    providerType?: string;
}
