import { SidebarHelpStandardLinks } from "@logex/framework/lg-layout";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { Dashboards } from "@codman/shared/types";

export interface HelpLink {
    url: string;
    name: string;
    icon: string;
    order: number;
}

enum HelpLinks {
    manual = "manual",
    FAQ = "FAQ",
    webinar = "webinar",
    serviceDesk = "serviceDesk",
}

export type HelpLinksId = keyof SidebarHelpStandardLinks;

export const supportLinkNL = "https://support.mrdm.com/nl/codman/";
export const supportLinkEN = "https://support.mrdm.com/en/codman/general-information/";
const dataRequestSupportLink =
    "https://support.mrdm.com/nl/data-exports/data-request-applicatie-eenvoudig-data-aanvragen/";
export const dataRequestSupportLinkJoining =
    "https://support.mrdm.com/nl/data-exports/voor-zorginstellingen-inzicht-in-uw-data-en-wetenschappelijk-onderzoek/";
const videosLink = "https://support.mrdm.com/nl/codman/videos/";
const serviceDeskLink = "mailto:servicedesk@mrdm.nl";

export const getSupportLinksHeader = (
    dashboard: Dashboards | undefined,
    tenant: string | undefined,
    registryId: string | null,
    translate: LgTranslateService,
): Partial<{ [key in HelpLinksId]: HelpLink }> => {
    return {
        manual: {
            name: translate.translate("APP._HelpInfo.Manual"),
            icon: "icon-manual",
            order: 1,
            url: getSupportLinkHeader(HelpLinks.manual, dashboard, tenant, registryId, translate),
        },
        FAQ: {
            name: translate.translate("APP._HelpInfo.FAQ"),
            icon: "icon-faq",
            order: 2,
            url: getSupportLinkHeader(HelpLinks.FAQ, dashboard, tenant, registryId, translate),
        },
        webinar: {
            name: translate.translate("APP._HelpInfo.Instruction_videos"),
            icon: "icon-webinar",
            order: 3,
            url: getSupportLinkHeader(HelpLinks.webinar, dashboard, tenant, registryId, translate),
        },
        serviceDesk: {
            name: translate.translate("APP._HelpInfo.Service_desk"),
            icon: "icon-helpcenter",
            order: 4,
            url: getSupportLinkHeader(
                HelpLinks.serviceDesk,
                dashboard,
                tenant,
                registryId,
                translate,
                true,
            ),
        },
    };
};

export function getSupportLinkHeader(
    helpLink: HelpLinks,
    dashboard: Dashboards | undefined,
    tenant: string | undefined,
    registryId: string | null,
    translate: LgTranslateService,
    removeParams = false,
): string {
    return getHelpLinkUrl(
        helpLink,
        dashboard,
        removeParams
            ? []
            : [
                  { id: "org", value: getTenantIdForUrl(tenant) },
                  { id: "set", value: getRegistryIdForUrl(registryId) },
              ],
        translate.getLanguage() === "nl-NL",
    );
}

function getHelpLinkUrl(
    helpLink: HelpLinks,
    dashboard: Dashboards | undefined,
    params: Array<{ id: string; value: string | null }>,
    isDutch = true,
): string {
    const queryParams = params.filter(param => param.value);
    const paramsString =
        queryParams.length === 0
            ? ""
            : "?" + queryParams.map(param => `${param.id}=${param.value}`).join("&");

    switch (helpLink) {
        case HelpLinks.FAQ:
            return getFAQLinkUrl(dashboard) + paramsString;
        case HelpLinks.webinar:
            return videosLink + paramsString;
        case HelpLinks.serviceDesk:
            return serviceDeskLink;
        case HelpLinks.manual:
        default:
            return isDutch ? getManualLinkUrl(dashboard) + paramsString : supportLinkEN;
    }
}

function getFAQLinkUrl(dashboard: Dashboards | undefined): string {
    switch (dashboard) {
        case Dashboards.Overview:
            return "https://support.mrdm.com/nl/codman/overview/veelgestelde-vragen/";
        default:
            return "https://support.mrdm.com/nl/codman/veelgestelde-vragen/";
    }
}

function getManualLinkUrl(dashboard: Dashboards | undefined): string {
    switch (dashboard) {
        case Dashboards.Overview:
            return "https://support.mrdm.com/nl/codman/overview/handleiding/";
        default:
            return supportLinkNL;
    }
}

export function getSupportLinkSidebar(
    dashboard: Dashboards,
    tenant: string | undefined,
    registryId: string | null,
    translate: LgTranslateService,
): string {
    if (translate.getLanguage() === "nl-NL") {
        let queryParams = [
            { id: "org", value: getTenantIdForUrl(tenant) },
            { id: "set", value: getRegistryIdForUrl(registryId) },
        ];
        queryParams = queryParams.filter(param => param.value);
        if (queryParams.length === 0) return getDashboardUrl(dashboard);
        return (
            getDashboardUrl(dashboard) +
            "?" +
            queryParams.map(param => `${param.id}=${param.value}`).join("&")
        );
    } else return supportLinkEN;
}

function getDashboardUrl(dashboard: Dashboards): string {
    switch (dashboard) {
        case Dashboards.Exploration:
            return getSpecificUrl("exploratief");
        case Dashboards.Indicators:
            return getSpecificUrl("indicatoren");
        case Dashboards.Descriptives:
            return getSpecificUrl("beschrijvingen");
        case Dashboards.Overview:
            return getSpecificUrl("overview");
        case Dashboards.Portal:
            return getSpecificUrl("registratieportaal");
        case Dashboards.DataRequest:
            return dataRequestSupportLink;
        default:
            return supportLinkNL;
    }
}

function getSpecificUrl(dashboard: string): string {
    return `https://support.mrdm.com/nl/codman/${dashboard}/introductie/`;
}

function getRegistryIdForUrl(registryId: string | null): string | null {
    if (!registryId) {
        return null;
    }
    switch (registryId) {
        case "DgeaDrce":
            return "dgea";
        case "DlcaS":
        case "DlcaR":
        case "DlcaL":
            return "dlca";
        case "NbcaR":
            return "nbca";
        case "DcraR":
        case "DcraWgz":
            return "dcra";
        case "DhfaWgz":
            return "dhfa";
        case "AmsmReporting":
            return "amsm";
        default:
            return registryId?.toLowerCase();
    }
}

function getTenantIdForUrl(tenantId: string | undefined): string | null {
    if (!tenantId) {
        return null;
    }
    switch (tenantId) {
        case "dicawgz":
            return "dica";
        default:
            return tenantId.toLowerCase();
    }
}
