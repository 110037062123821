import { Injectable, inject } from "@angular/core";
import { HttpResponse } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { lowerFirst } from "lodash";

import { ServerGatewayBase } from "@logex/framework/lg-application";

import { SharedConfigService } from "@codman/shared/util-logex-framework-setup";
import { TimeRange } from "@codman/shared/util-filters";
import { IStatus } from "@codman/shared/types";
import { TrendInterval } from "@codman/shared/assets";

import { BenchmarkType } from "@codman/exploration/util-interfaces";

import {
    FilterCounts,
    IOutcome,
    IOutcomeTrend,
    IPatientCount,
    IRegistryStructure,
    ITrendMoment,
    CategoryQueryParams,
    SEPARATORS,
    TimeRangeFilterRange,
    FilterParams,
    RangeQueryParams,
    StringQueryParams,
    NonFilterParams,
    ORG_ID_HEADER,
    OutcomesConfiguration,
    IRegistryConfiguration,
    OutcomeConfiguration,
    ISubsetConfiguration,
    AnalysisUnit,
    PatientList,
    ISection,
    IProvider,
    IDataExportPage,
    ReferenceOutcome,
    FilterComparison,
    NumberQueryParams,
    ISortDirection,
    IBenchmarkConfiguration,
    IEventAnalysis,
    BenchmarkParams,
    TimeRangeQueryParams,
} from "./data-access-api.types";

@Injectable({
    providedIn: "root",
})
export class DataAccessApiService extends ServerGatewayBase {
    protected _configService = inject(SharedConfigService);

    constructor() {
        super();
        const apiUrl = this._configService.getConfiguration()?.apiUrl;
        if (apiUrl != null) {
            this._setBaseUrl(apiUrl);
        } else {
            console.error("Cannot set application base API url based on configuration.");
        }
    }

    /**
     * Configuration
     */
    getRegistryStructure(
        registryId: string,
        organizationId: number,
    ): Observable<IRegistryStructure> {
        return this._get<IRegistryStructure>(`${registryId}/structure`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    getRegistryConfiguration(
        registryId: string,
        organizationId: number,
    ): Observable<IRegistryConfiguration> {
        return this._get<IRegistryConfiguration>(`${registryId}/registryconfiguration`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        }).pipe(
            // Backend sends First letter upercase and it is easier for us to work with lowecase ids
            map(result => {
                const benchmarkTypes = result.benchmarkTypes.map(benchmarkType =>
                    lowerFirst(benchmarkType),
                ) as BenchmarkType[];
                return { ...result, benchmarkTypes };
            }),
        );
    }

    getBenchmarkConfiguration(
        registryId: string,
        organizationId: number,
    ): Observable<IBenchmarkConfiguration> {
        return this._get<IBenchmarkConfiguration>(`${registryId}/benchmarkConfiguration`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    getSubsetConfiguration(
        registryId: string,
        subsetId: string,
        organizationId: number,
    ): Observable<ISubsetConfiguration> {
        // backend returning analysisLevel which is mapped for clearer naming in FE
        return this._get<ISubsetConfiguration & { analysisLevel: AnalysisUnit }>(
            `${registryId}/${subsetId}/subsetconfiguration`,
            {
                headers: {
                    [ORG_ID_HEADER]: organizationId.toString(),
                },
            },
        ).pipe(map(result => ({ ...result, analysisUnit: result.analysisLevel })));
    }

    getOutcomeConfiguration(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
    ): Observable<OutcomeConfiguration> {
        return this._get<OutcomeConfiguration>(
            `${registryId}/${subsetId}/${outcomeId}/outcomeConfiguration`,
            {
                headers: {
                    [ORG_ID_HEADER]: organizationId.toString(),
                },
            },
        );
    }

    getPageOutcomes(
        registryId: string,
        subsetId: string,
        pageId: string,
        organizationId: number,
    ): Observable<ISection[]> {
        return this._get<ISection[]>(`${registryId}/${subsetId}/${pageId}/pageoutcomes`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    getOutcomesConfiguration(
        registryId: string,
        subsetId: string,
        organizationId: number,
    ): Observable<OutcomesConfiguration> {
        return this._get<OutcomesConfiguration>(`${registryId}/${subsetId}/outcomesConfiguration`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    /**
     * @deprecated use getBenchmarkConfiguration instead
     */
    getSharedProviders(registryId: string, organizationId: number): Observable<IProvider[]> {
        return this._get<IProvider[]>(
            `${registryId}/sharedproviders?providerId=${organizationId}`,
            {
                headers: {
                    [ORG_ID_HEADER]: organizationId.toString(),
                },
            },
        );
    }

    /**
     * Filters
     */
    getFilterCounts(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<FilterCounts> {
        return this.getFiltered<FilterCounts>(
            `${registryId}/${subsetId}/${outcomeId}/filtercount`,
            organizationId,
            filters,
            { timeRange },
        );
    }

    getYearsFilterCount(
        registryId: string,
        subsetId: string,
        organizationId: number,
    ): Observable<TimeRangeFilterRange> {
        return this._get<TimeRangeFilterRange>(`${registryId}/${subsetId}/yearsfilterrange`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    /**
     * Outcomes
     */
    getSingleOutcome(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IOutcome> {
        return this.getFiltered<IOutcome>(
            `${registryId}/${subsetId}/${outcomeId}/outcome`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        );
    }

    getSectionOutcomes(
        registryId: string,
        subsetId: string,
        pageId: string,
        sectionId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IOutcome[]> {
        return this.getFiltered<IOutcome[]>(
            `${registryId}/${subsetId}/${pageId}/${sectionId}/outcomes`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        );
    }

    getOtherOutcomeProviders(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<ReferenceOutcome[]> {
        return this.getFiltered<ReferenceOutcome[]>(
            `${registryId}/${subsetId}/${outcomeId}/outcomesotherproviders`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        );
    }

    getOutcomeTrend(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        interval: TrendInterval,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams,
        filters?: FilterParams,
    ): Observable<IOutcomeTrend[]> {
        return this.getFiltered<IOutcomeTrend[]>(
            `${registryId}/${subsetId}/${outcomeId}/trend/${interval}`,
            organizationId,
            filters,
            { ...benchmarkApiParams },
        );
    }

    getEventAnalysis(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IEventAnalysis> {
        return this.getFiltered<IEventAnalysis>(
            `${registryId}/${subsetId}/${outcomeId}/eventAnalysis`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        );
    }

    getFilterComparisons(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        filterGroupId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        measurementMoment?: string,
        filters?: FilterParams,
    ): Observable<FilterComparison[]> {
        return this.getFiltered<FilterComparison[]>(
            `${registryId}/${subsetId}/${outcomeId}/filtercomparison/${filterGroupId}`,
            organizationId,
            filters,
            {
                timeRange,
                measurementMoment,
                ...benchmarkApiParams,
            },
        );
    }

    /**
     * Patient Counts
     */
    getPatientsCount(
        registryId: string,
        subsetId: string,
        organizationId: number,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IPatientCount> {
        return this.getFiltered<IPatientCount>(
            `${registryId}/${subsetId}/patientscount`,
            organizationId,
            filters,
            {
                timeRange,
            },
        );
    }

    getPatientTrend(
        registryId: string,
        subsetId: string,
        interval: TrendInterval,
        organizationId: number,
        filters?: FilterParams,
    ): Observable<ITrendMoment[]> {
        return this.getFiltered<ITrendMoment[]>(
            `${registryId}/${subsetId}/patientscount/trend/${interval}`,
            organizationId,
            filters,
        );
    }

    /**
     * Patient List
     */
    getPatientsList(
        registryId: string,
        subsetId: string,
        organizationId: number,
        timeRange?: TimeRange,
        filters?: FilterParams,
        offset?: number,
        count?: number,
        sortBy?: string,
        sortType?: ISortDirection,
    ): Observable<PatientList> {
        return this.getFiltered<PatientList>(
            `${registryId}/${subsetId}/patientslist`,
            organizationId,
            filters,
            {
                timeRange,
                offset,
                count,
                sortBy,
                sortType,
            },
        );
    }

    /**
     * Patient export
     */
    getPatientExport(
        registryId: string,
        subsetId: string,
        organizationId: number,
        params: {
            language: string;
        },
        timeRange: TimeRange,
        filters?: FilterParams,
    ): Observable<HttpResponse<Blob>> {
        let filtersQuery: Record<string, string> = {};
        if (filters?.length) {
            filtersQuery = this._getFiltersQueryParamsString(filters, "searchCriteria");
        }
        let otherParams: Record<string, string> = {};
        if (
            timeRange?.minYear !== null &&
            timeRange?.maxYear !== null &&
            timeRange?.minMonth !== null &&
            timeRange?.maxMonth
        ) {
            otherParams = this._getOtherQueryParams({ timeRange });
        }
        return this._get<Blob>(`${registryId}/${subsetId}/patientslist/report`, {
            params: {
                ...params,
                ...filtersQuery,
                ...otherParams,
            },
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
            responseType: "blob",
            observe: "response",
        });
    }

    /**
     * Data for export
     */
    getDataExport(
        registryId: string,
        subsetId: string,
        interval: TrendInterval,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IDataExportPage[]> {
        return this.getFiltered<IDataExportPage[]>(
            `${registryId}/${subsetId}/dataexport/${interval}`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        );
    }

    /**
     * Other
     */
    getFiltered<T>(
        url: string,
        organizationId: number,
        filters?: FilterParams,
        nonFilterParams?: NonFilterParams,
    ): Observable<T> {
        let filtersQuery: Record<string, string> = {};
        let otherParams: Record<string, string> = {};
        const otherQueryParams = { ...nonFilterParams };
        if (nonFilterParams) {
            otherParams = this._getOtherQueryParams(otherQueryParams);
        }

        if (filters?.length) {
            filtersQuery = this._getFiltersQueryParamsString(filters, "searchCriteria");
        }

        return this._get<T>(url, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
            params: {
                ...filtersQuery,
                ...otherQueryParams,
                ...otherParams,
            },
        });
    }

    private _getOtherQueryParams(otherParams: NonFilterParams): Record<string, string> {
        const params: Record<string, string> = {};

        const connectBenchmarkProviders = otherParams.connectBenchmarkProviders;
        if (connectBenchmarkProviders) {
            const paramValue = this._getCategoryQueryString({
                type: "category",
                id: "connectBenchmarkProviders",
                values: connectBenchmarkProviders,
            });
            params["connectBenchmarkProviders"] = paramValue;
            delete otherParams.connectBenchmarkProviders;
        }

        const regions = otherParams.regions;
        if (regions) {
            const paramValue = this._getCategoryQueryString({
                type: "category",
                id: "regions",
                values: [regions],
            });
            params["regions"] = paramValue;
            delete otherParams.regions;
        }

        const years = otherParams.timeRange;
        if (years?.minYear && years?.maxYear && years?.minMonth && years?.maxMonth) {
            const paramValue = this._getTimeRangeQueryString({
                type: "timeRange",
                id: "timeRange",
                minYear: years.minYear,
                maxYear: years.maxYear,
                minMonth: years.minMonth,
                maxMonth: years.maxMonth,
            });
            params["timeRange"] = paramValue;
        }
        if (years) {
            delete otherParams.timeRange;
        }

        const providerType = otherParams.providerType;
        if (providerType) {
            const paramValue = this._getStringQueryString({
                type: "string",
                id: "providerType",
                value: providerType,
            });
            params["providerType"] = paramValue;
            delete otherParams.providerType;
        }

        return params;
    }

    private _getFiltersQueryParamsString(
        params: FilterParams,
        dictionaryName: string,
    ): Record<string, string> {
        const filterParams: Record<string, string> = {};
        params.forEach(param => {
            let filterValue: string;
            switch (param.type) {
                case "category":
                    filterValue = this._getCategoryQueryString(param);
                    break;
                case "range":
                    filterValue = this._getRangeQueryString(param);
                    break;
                case "string":
                    filterValue = this._getStringQueryString(param);
                    break;
                case "number":
                    filterValue = this._getNumberQueryString(param);
                    break;
                default:
                    filterValue = "";
            }
            const filterKey = `${dictionaryName}[${param.id}]`;
            filterParams[filterKey] = filterValue;
        });

        return filterParams;
    }

    private _getCategoryQueryString(params: CategoryQueryParams): string {
        const filterValuesQuery = params.values.join(SEPARATORS.values);
        return filterValuesQuery;
    }

    private _getRangeQueryString(params: RangeQueryParams): string {
        const filterValuesQuery = params.min + SEPARATORS.range + params.max;
        return filterValuesQuery;
    }

    private _getTimeRangeQueryString(params: TimeRangeQueryParams): string {
        const filterValuesQuery =
            params.minYear +
            SEPARATORS.items +
            params.minMonth +
            SEPARATORS.range +
            params.maxYear +
            SEPARATORS.items +
            params.maxMonth;
        return filterValuesQuery;
    }

    private _getStringQueryString(params: StringQueryParams): string {
        return params.value;
    }

    private _getNumberQueryString(params: NumberQueryParams): string {
        return params.value.toString();
    }

    /**
     * Status
     */
    getStatus(): Observable<IStatus> {
        return this._get<IStatus>(`/`);
    }
}
