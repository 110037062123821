import { inject, Injectable } from "@angular/core";

import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from "rxjs";
import { filter, map, pairwise } from "rxjs/operators";
import { capitalize } from "lodash";

import { LgTranslateService } from "@logex/framework/lg-localization";

import {
    SharedColoringService,
    SharedConfigService,
} from "@codman/shared/util-logex-framework-setup";

import {
    IBenchmarkConfiguration,
    Benchmark,
    INITIAL_BENCHMARK,
    Regions,
    IRegionalBenchmark,
    IConnectBenchmark,
    IProviderTypeBenchmark,
    INITIAL_CA_BENCHMARK,
} from "./feature-benchmarking.types";

@Injectable({
    providedIn: "root",
})
export class FeatureBenchmarkingService {
    private _coloringService = inject(SharedColoringService);
    private _lgTranslateService = inject(LgTranslateService);
    private _sharedConfigService = inject(SharedConfigService);

    readonly colors: { [key: string]: string } = {
        ownProvider: this._coloringService.getTheme().provider,
        benchmark: this._coloringService.getTheme().benchmark,
        national: this._coloringService.getTheme().benchmark,
        nationalBenchmark: this._coloringService.getTheme().benchmark,
        regional: this._coloringService.getTheme().benchmark,
        international: this._coloringService.getTheme().benchmark,
        internationalBenchmark: this._coloringService.getTheme().benchmark,
        connect: this._coloringService.getTheme().benchmarkConnect,
        connectBenchmark: this._coloringService.getTheme().benchmarkConnect,
        sharedOrganization: this._coloringService.getTheme().sharedOrganization,
        providerType: this._coloringService.getTheme().benchmarkProviderType,
        providerTypeBenchmark: this._coloringService.getTheme().benchmarkProviderType,
    };

    /**
     * Benchmark configuration
     */
    private readonly _benchmarkConfiguration$ = new ReplaySubject<IBenchmarkConfiguration>();

    readonly availableConnectProviders$ = this._benchmarkConfiguration$.pipe(
        map(benchmarkConfiguration => benchmarkConfiguration.connect),
    );

    /**
     * Benchmark selection
     */
    private readonly _selectedBenchmark$ = new BehaviorSubject<Benchmark>(
        this.getInitialBenchmark(),
    );

    private getInitialBenchmark(): Benchmark {
        return this._sharedConfigService.getCountry() === "CA"
            ? INITIAL_CA_BENCHMARK
            : INITIAL_BENCHMARK;
    }

    readonly selectedBenchmark$ = combineLatest([
        this._selectedBenchmark$,
        this._benchmarkConfiguration$,
    ]).pipe(
        map(([selectedBenchmark, configuration]) => {
            const isSelectionValid = this._isBenchmarkSelectionValid(
                selectedBenchmark,
                configuration,
            );
            if (isSelectionValid) {
                return selectedBenchmark;
            }
            return this._getDefaultBenchmark(configuration);
        }),
    );

    readonly selectedConnectProviders$ = this.selectedBenchmark$.pipe(
        map(benchmark => (benchmark.type === "connect" ? benchmark.connectProviders : undefined)),
    );

    readonly selectedProviderType$ = this.selectedBenchmark$.pipe(
        map(benchmark => (benchmark.type === "providerType" ? benchmark.providerType : undefined)),
        // filter((providerType): providerType is string => providerType != null),
    );

    readonly selectedRegions$ = this.selectedBenchmark$.pipe(
        map(benchmark =>
            benchmark.type === "regional" ||
            benchmark.type === "connect" ||
            benchmark.type === "providerType"
                ? benchmark.regions
                : undefined,
        ),
        filter((regions): regions is Regions => regions != null),
    );

    readonly selectedBenchmarkColor$ = this.selectedBenchmark$.pipe(
        map(benchmark => {
            return this.colors[benchmark.type];
        }),
    );

    readonly selectedBenchmarkLabel$ = this.selectedBenchmark$.pipe(
        map(benchmark => this._getSelectedBenchmarkLabel(benchmark)),
    );

    constructor() {
        this._resetConnectProvidersWhenAvailableProvidersChange();
    }

    initialize(configuration$: Observable<IBenchmarkConfiguration>): void {
        configuration$.subscribe(configuration => {
            this._benchmarkConfiguration$.next(configuration);
        });
    }

    updateSelectedBenchmark(selectedBenchmark: Benchmark): void {
        this._selectedBenchmark$.next(selectedBenchmark);
    }

    resetSelectedBenchmark(): void {
        this._selectedBenchmark$.next(this.getInitialBenchmark());
    }

    getIndicatorsColors(): { [key: string]: string } {
        return {
            ownProvider: this.colors["ownProvider"],
            benchmark: this.colors["national"],
            connectBenchmark: this.colors["connect"],
            providerTypeBenchmark: this.colors["providerType"],
        };
    }

    getExplorationColors(): { [key: string]: string } {
        return {
            ownProvider: this.colors["ownProvider"],
            nationalBenchmark: this.colors["national"],
            internationalBenchmark: this.colors["international"],
            connectBenchmark: this.colors["connect"],
            sharedOrganization: this.colors["sharedOrganization"],
            providerTypeBenchmark: this.colors["providerType"],
        };
    }

    private _isBenchmarkSelectionValid(
        selectedBenchmark: Benchmark,
        configuration: IBenchmarkConfiguration,
    ): boolean {
        switch (selectedBenchmark.type) {
            case "regional":
                return this._validateRegion(selectedBenchmark, configuration);
            case "connect":
                return this._validateConnect(selectedBenchmark, configuration);
            case "providerType":
                return this._validateProviderType(selectedBenchmark, configuration);
            default:
                console.warn("Using a deprecated benchmark type");
                return true;
        }
    }

    private _validateRegion(
        selectedBenchmark: IRegionalBenchmark,
        configuration: IBenchmarkConfiguration,
    ): boolean {
        return selectedBenchmark.regions.every(selectedRegion =>
            configuration.regions.find(availableRegion => availableRegion === selectedRegion),
        );
    }

    private _validateConnect(
        selectedBenchmark: IConnectBenchmark,
        configuration: IBenchmarkConfiguration,
    ): boolean {
        return (
            selectedBenchmark.connectProviders.length > 0 &&
            selectedBenchmark.connectProviders.every(selectedProvider =>
                configuration.connect.find(
                    availableProvider => availableProvider.id === selectedProvider,
                ),
            )
        );
    }

    private _validateProviderType(
        selectedBenchmark: IProviderTypeBenchmark,
        configuration: IBenchmarkConfiguration,
    ): boolean {
        return configuration.providerTypes.includes(selectedBenchmark.providerType);
    }

    private _getDefaultBenchmark({ defaultRegion, regions }: IBenchmarkConfiguration): Benchmark {
        let regionsSelection: Regions;
        if (defaultRegion && regions.includes(defaultRegion)) {
            regionsSelection = [defaultRegion];
        } else {
            const [firstRegion, ...otherRegions] = regions;
            regionsSelection = firstRegion != null ? [firstRegion, ...otherRegions] : ["nl"];
        }
        return {
            type: "regional",
            regions: regionsSelection,
        };
    }

    private _getSelectedBenchmarkLabel(benchmark: Benchmark): string {
        if (benchmark.type === "connect") {
            return "APP._Shared.ConnectBenchmark";
        } else if (benchmark.type === "regional") {
            return this._getBenchmarkRegionalLabel(benchmark.regions);
        } else if (benchmark.type === "providerType") {
            return this._lgTranslateService.translate(
                `APP._Shared.BenchmarkSelector.Benchmarks.Providertype.Providers.${benchmark.providerType}.Abbreviation`,
            );
            // return "APP._Shared.ProviderTypeBenchmark";
        }
        return `APP._Shared.BenchmarkSelector.Benchmarks.${capitalize(benchmark.type)}.ShortLabel`;
    }

    private _getBenchmarkRegionalLabel(regions: string[]): string {
        const benchmarkLabel = this._lgTranslateService.translate(
            "APP._Shared.BenchmarkSelector.Benchmark",
        );

        if (regions.length === 1) {
            return `${regions[0].toUpperCase()} ${benchmarkLabel}`;
        }

        const regionsList = regions.map(region => region.toUpperCase()).join(", ");
        return `${benchmarkLabel} ${regionsList}`;
    }

    private _resetConnectProvidersWhenAvailableProvidersChange(): void {
        this.availableConnectProviders$
            .pipe(pairwise())
            .subscribe(([previousProviders, currentProviders]) => {
                const previousProviderIds = previousProviders.map(provider => provider.id).sort();
                const currentProviderIds = currentProviders.map(provider => provider.id).sort();
                if (JSON.stringify(previousProviderIds) !== JSON.stringify(currentProviderIds)) {
                    this.resetSelectedBenchmark();
                }
            });
    }
}
